import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DifferenceIcon from "@mui/icons-material/Difference";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { COPY_SHIPMENT_FOR_IMPORT_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  ADMIN_BID_COPY_STATUS,
  ADMIN_EXPORT_SHIPMENT_COPY_STATUS,
} from "@sellernote/_shared/src/constants/forwarding/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  BidCopyStatus,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import RequestQuoteToPartnerModal from "../../_components/RequestQuoteToPartnerModal";
import ContactingPartnerModal from "./ContactingPartnerModal";
import useBidCopyTargetCheckbox from "./useBidCopyCheckbox";

function BidCopyConditions({
  bidCopyModalStatus,
  setShowsBidCopyModal,
  bidDetail,
}: {
  bidCopyModalStatus: BidCopyStatus;
  setShowsBidCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { id }: { id: string } = useParams();

  const { id: bidId, isImport, team } = bidDetail;

  const copyStatusList = isImport
    ? ADMIN_BID_COPY_STATUS
    : ADMIN_EXPORT_SHIPMENT_COPY_STATUS;

  const [copyStatusOfRadio, setCopyStatusOfRadio] =
    useState<BidCopyStatus>(bidCopyModalStatus);
  const [showsRequestQuoteToPartnerModal, setShowsRequestQuoteToPartnerModal] =
    useState(false);
  const [copiedBidId, setCopiedBidId] = useState(0);
  const [showsContactingPartnerModal, setShowsContactingPartnerModal] =
    useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const { data: partnerQuote } = ADMIN_BID_QUERY.useGetAdminBidPartnerQuote({
    bidId,
  });

  const { mutate: copyShipmentForImport } =
    ADMIN_BID_QUERY.useCopyShipmentForImport();

  const { mutate: copyShipmentForExport } =
    ADMIN_BID_QUERY.useCopyShipmentForExport();

  const { BidCopyTargetCheckbox, bidCopyTargetValue } =
    useBidCopyTargetCheckbox({ copyStatusOfRadio, isImport });

  const { contactBookingPartner } = bidCopyTargetValue;

  const copyShipment = bidDetail.isImport
    ? copyShipmentForImport
    : copyShipmentForExport;

  const checkIfRadioButtonIsDisabled = useCallback(
    (radioStatus: BidCopyStatus) => {
      if (
        bidCopyModalStatus === "committed" ||
        bidCopyModalStatus === "estimating"
      ) {
        if (radioStatus === "committed" || radioStatus === "estimating") {
          return false;
        }
        return true;
      }

      if (bidCopyModalStatus === "waiting") {
        if (
          radioStatus === "committed" ||
          radioStatus === "estimating" ||
          radioStatus === "waiting"
        ) {
          return false;
        }
        return true;
      }

      if (isImport && bidCopyModalStatus === "waitingForExporterInfo") {
        if (
          radioStatus === "beforeContactPartner" ||
          radioStatus === "contactingPartner"
        ) {
          return true;
        }
        return false;
      }

      if (isImport && bidCopyModalStatus === "beforeContactPartner") {
        if (radioStatus === "contactingPartner") {
          return true;
        }

        return false;
      }

      return false;
    },
    [bidCopyModalStatus, isImport]
  );

  // receiverList는 서버에서 확인해줘서 요청 시 필요없지만 language로 영문품목 확인을 위해 데이터 생성
  const receiverList = useMemo(() => {
    return partnerQuote?.list.map((v) => {
      return {
        language: v.language,
        mainManager: "",
        needsPlainTextEmail: v.needsPlainTextEmail,
        name: v.name,
        emailList: [""],
        partnerCompany: v.id,
      };
    });
  }, [partnerQuote?.list]);

  const handleBidCopyStatusChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setCopyStatusOfRadio(value as BidCopyStatus);
  };

  const handleBidCopyModalClose = useCallback(() => {
    history.push(`/bid/detail/${copiedBidId}`);
    setShowsRequestQuoteToPartnerModal(false);
    setShowsBidCopyModal(false);
  }, [copiedBidId, history, setShowsBidCopyModal]);

  const handleAdminBidCopy = () => {
    setIsCopying(true);

    const handleSuccessResponse = (res: {
      data: COPY_SHIPMENT_FOR_IMPORT_RES;
    }) => {
      setIsCopying(false);

      if (bidCopyTargetValue.contactEstimatePartner) {
        // 복사 할 의뢰에서 컨택한 견적파트너가 없는 경우 알려준다.
        if (partnerQuote && partnerQuote?.list.length < 1) {
          setCopiedBidId(res.data.id);
          handleSnackbarOpen("의뢰복사는 했지만 컨택할 견적파트너가 없습니다.");
          history.push(`/bid/detail/${res.data.id}`);
          setShowsRequestQuoteToPartnerModal(false);
          setShowsBidCopyModal(false);
          return;
        }

        setCopiedBidId(res.data.id);
        setShowsRequestQuoteToPartnerModal(true);
        return;
      }

      history.push(`/bid/detail/${res.data.id}`);
      setShowsBidCopyModal(false);
    };

    copyShipment(
      {
        pathParams: { shipmentId: Number(id) },
        targetStatus: copyStatusOfRadio,
        copyTarget: bidCopyTargetValue,
      },
      {
        onSuccess: handleSuccessResponse,
        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          setIsCopying(false);
        },
      }
    );
  };

  return (
    <>
      <Box>
        <Box display={"flex"} gap={3}>
          <RadioGroup
            value={copyStatusOfRadio}
            onChange={handleBidCopyStatusChange}
          >
            <InputLabel>신규 의뢰 상태</InputLabel>

            {copyStatusList.map(({ name, label }) => (
              <FormControlLabel
                key={name}
                value={name}
                control={
                  <Radio
                    disabled={checkIfRadioButtonIsDisabled(name)}
                    size="small"
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>

          <Divider flexItem orientation="vertical" />

          {BidCopyTargetCheckbox}
        </Box>

        {showsRequestQuoteToPartnerModal && receiverList && bidDetail && (
          <RequestQuoteToPartnerModal
            isBidCopy={true}
            receiverList={receiverList}
            bidDetail={bidDetail}
            showsRequestQuoteModal={showsRequestQuoteToPartnerModal}
            setShowsRequestQuoteModal={setShowsRequestQuoteToPartnerModal}
            handleBidCopyModalClose={handleBidCopyModalClose}
            copiedBidId={copiedBidId}
          />
        )}

        <Divider sx={{ mb: 2, mt: 2 }}>
          {team.company}의 의뢰번호 {id}를 복사해서 신규 의뢰를 생성합니다.
        </Divider>

        <Box>
          <Button
            startIcon={<DifferenceIcon />}
            variant="contained"
            onClick={() => {
              /** 부킹파트너 컨택 체크시에만 컨택 요청 모달 노출 */
              if (
                copyStatusOfRadio === "contactingPartner" &&
                contactBookingPartner
              ) {
                setShowsContactingPartnerModal(true);
                return;
              }

              handleAdminBidCopy();
            }}
            disabled={isCopying}
            fullWidth
            size="small"
          >
            의뢰 복사
          </Button>
        </Box>
      </Box>

      {showsContactingPartnerModal && (
        <ContactingPartnerModal
          showsContactingPartnerModal={showsContactingPartnerModal}
          setShowsContactingPartnerModal={setShowsContactingPartnerModal}
          bidDetail={bidDetail}
          copyStatusOfRadio={copyStatusOfRadio}
          bidCopyTargetValue={bidCopyTargetValue}
          setShowsBidCopyModal={setShowsBidCopyModal}
        />
      )}
    </>
  );
}

export default BidCopyConditions;
